.custom-marker-icon {
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid white !important;
}

.custom-marker-icon-clicked {
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #EB9300 !important;
}

.custom-pin-places {
    position: absolute;
    border-radius: 50%;
    border: 1px solid #007795;
    background-color: #008EB3;
    width: 20px;
    height: 20px;
}

.clicked-marker-blue {
    border: 4px solid #008EB3 !important;
    background-color: white;
    width: 16px;
    height: 16px;
}

.scroll-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

.scroll-container::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}

.cluster-icon-details {
    border: 2px solid #fff;
    border-radius: 0.375rem;
    box-shadow: 0 3px 19px -5px #333;
    box-sizing: border-box;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.photographies-details {
    border: 1px solid rgba(255, 255, 255, 0.945);
    border-radius: 50%;
    box-shadow: 0 3px 19px -5px #333;
    box-sizing: border-box !important;
}

.photographies-details div {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #00cc00;
    color: white;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-size: 10px;
    line-height: 8px;
    padding: 3px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
    /* z-index: 2000; */
}

.photographies-details:hover {
    z-index: 999 !important;
}

.leaflet-marker-icon.photographies-details img {
    width: 34px !important;
    height: 34px;
    border-radius: 50%;
    object-fit: cover;
}

.custom-popup {
    background-color: red;
    border: 2px solid green;
    padding: 10px;
    border-radius: 500px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}