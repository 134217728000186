.random-location-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    user-select: none;
    pointer-events: none;
}

.icons-text {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    text-align: center;
}

.icons-text svg {
    color: #666666;
    font-size: 4rem;
}

.random-location-image-details {
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column !important;
    align-items: center;
    cursor: pointer;
}

.random-location-image-details:hover h3, .random-location-image-details:hover h5 {
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.9);
}

.random-location-image-title {
    text-align: center;
    font-size: clamp(1.5rem, 5vw, 3rem) !important;
    width: 100%;
    padding-bottom: 8px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}


.random-location-image-subtitle {
    text-align: center;
    font-size: clamp(0.8rem, 5vw, 1.3rem) !important;
    width: 100%;
    padding-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9);
}

.button-location-page {
    width: fit-content;
    color: white;
}

.suggested-images {
    width: 120px;
    height: 120px;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    border-radius: 50%;
    border: 2px solid #f5f5f5;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    cursor: pointer;
}

.icons-container {
    justify-content: center;
    align-content: center;
    flex-direction: row;
    padding-bottom: 15px;
}

.container-suggested-images {
    justify-content: center;
    align-content: center;
    flex-direction: row;
    padding-bottom: 10px;
}

div.suggested-images:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.container-suggested-images span {
    padding-top: 10px;
    font-size: 17px;
    padding: 10px 5px 20px 5px;
    color: #868686;
    cursor: pointer;
}

.container-suggested-images span:hover {
    color: #464646;
}

.random-location-footer {
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.random-image-to-the-map-button {
    text-align: center;
    padding: 15px;
}

.login-button-front-page:last-child {
    font-weight: 500 !important;
    color: black !important;
    background-color: rgba(240, 240, 240, 0.9) !important;
    width: fit-content !important;
    padding: 5px 10px !important;
    min-width: fit-content !important;
    font-size: 0.75em !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    box-shadow: unset !important;
}

.login-button-front-page:last-child:hover {
    background-color: rgba(240, 240, 240, 0.75) !important
}

@media screen and (max-width: 1024px) {
    .random-location-image-details {
        top: 200px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.info-text-home-page {
    text-align: left;
    padding-bottom: 15px;
}

@media screen and (max-width: 768px) {
    .random-location-image-details {
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media screen and (max-width: 640px) {

    .random-location-footer {
        justify-content: center;
    }

    .footer-compliances {
        padding: 5px;
    }
}

@media screen and (max-width: 560px) {
    .suggested-images {
        width: 110px;
        height: 110px;
    }

    .info-text-home-page {
        text-align: start;
    }

    .icons-text svg {
        font-size: 3rem;
    }

    .icons-text span {
        font-size: small;
    }

    .container-suggested-images span {
        padding-bottom: 25px;
    }

    .logo-container {
        padding-left: 0 !important;
    }

    .icons-container div {
        padding: 0 5px 25px 5px;
    }

    .random-location-footer {
        flex-direction: column !important;
        justify-content: center;
    }

    .random-location-footer .logo-container h4 {
        display: inline-flex;
    }

    .footer-compliances {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 2px;
    }
}

/* arrows */

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 90%;
    left: 50%;
    right: 50%;
    width: fit-content;
    transform: translate(-50%, -50%) !important;
    cursor: pointer;
}

.content h5 {
    margin: 0 0 20px;
}

/* Arrow & Hover Animation */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
}

.chevron {
    position: absolute;
    width: 2.1rem;
    height: 0.48rem;
    opacity: 0;
    transform: scale(0.3);
    -webkit-animation: move-chevron 3s ease-out infinite;
    animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
    -webkit-animation: move-chevron 3s ease-out 1s infinite;
    animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
    -webkit-animation: move-chevron 3s ease-out 2s infinite;
    animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    background: white;
}

.chevron:before {
    left: 0;
    transform: skewY(30deg);
}

.chevron:after {
    right: 0;
    width: 50%;
    transform: skewY(-30deg);
}

@-webkit-keyframes move-chevron {
    25% {
        opacity: 1;
    }

    33.3% {
        opacity: 1;
        transform: translateY(2.28rem);
    }

    66.6% {
        opacity: 1;
        transform: translateY(3.12rem);
    }

    100% {
        opacity: 0;
        transform: translateY(4.8rem) scale(0.5);
    }
}

@keyframes move-chevron {
    25% {
        opacity: 1;
    }

    33.3% {
        opacity: 1;
        transform: translateY(2.28rem);
    }

    66.6% {
        opacity: 1;
        transform: translateY(3.12rem);
    }

    100% {
        opacity: 0;
        transform: translateY(4.8rem) scale(0.5);
    }
}