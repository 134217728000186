.custom-div-icon {
    z-index: 998 !important;
}

.custom-div-icon span {
    z-index: inherit;
    top: -6px !important;
    right: -10px !important;
    padding: 2px !important;
    border: 1px solid rgb(0 0 0 / 20%);
    box-shadow: unset !important;
    z-index: 9999 !important;
}

.leaflet-marker-icon {
    z-index: 998 !important;
    object-fit: cover;
}


.custom-pin {
    position: absolute;
    border-radius: 50%;
    border: 1px solid #bf7900;
    background-color: #EB9300;
    width: 25px;
    height: 25px;
}

.custom-pin-disabled {
    position: absolute;
    border-radius: 50%;
    /* border: 4px solid #008EB3; */
    background-color: rgb(179, 179, 179);
    border: 1px solid rgba(128, 128, 128, 0.503);
    width: 25px;
    height: 25px;
    z-index: 998 !important;
}

.custom-pin-map-places {
    position: absolute;
    border-radius: 50%;
    border: 1px solid #007795;
    background-color: #008EB3;
    width: 20px;
    height: 20px;
}

.clicked-marker-map-blue {
    border: 4px solid #008EB3 !important;
    background-color: white;
    width: 16px;
    height: 16px;
}

.cluster-icon {
    border: 2px solid #fff;
    border-radius: 0.375rem;
    box-shadow: 0 3px 19px -5px #333;
    box-sizing: border-box;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
}

.leaflet-marker-icon.leaflet-interactive span {
    position: absolute;
    font-weight: 600;
    top: -3px;
    right: -7px;
    color: #555;
    background-color: #fff;
    border-radius: 8px;
    height: 12px;
    min-width: 12px;
    line-height: 12px;
    text-align: center;
    padding: 3px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.photographies {
    border: 1px solid rgba(255, 255, 255, 0.945);
    border-radius: 50%;
    box-shadow: 0 3px 19px -5px #333;
    box-sizing: border-box !important;
    z-index: 998 !important;
}

.photographies div {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #00cc00;
    color: white;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-size: 10px;
    line-height: 8px;
    padding: 3px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
    /* z-index: 2000; */
}

.photographies:hover {
    z-index: 999 !important;
}

.leaflet-marker-icon.photographies img {
    width: 44px !important;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
}

.clicked-marker-yellow-border {
    border: 2px solid #EB9300 !important;
    border-radius: 50%;
    object-fit: cover;
}

.leaflet-marker-icon.leaflet-interactive span {
    position: absolute;
    font-weight: 600;
    top: -3px;
    right: -7px;
    color: #555;
    background-color: #fff;
    border-radius: 8px;
    height: 12px;
    min-width: 12px;
    line-height: 12px;
    text-align: center;
    padding: 3px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.clicked-marker-yellow {
    border: 4px solid #EB9300 !important;
    background-color: white;
    width: 20px;
    height: 20px;
}

/* Zoom control start */
.leaflet-control-zoom-in {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.leaflet-control-zoom-out {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.leaflet-bar {
    /* bottom: 40px; */
    border: initial !important;
}

.leaflet-bar a {
    color: #0000008a !important;
}

.leaflet-bar a:active {
    color: #262626 !important;
}

.leaflet-bar {
    bottom: 0;
}

@media screen and (max-width: 560px) {
    .leaflet-touch .leaflet-bar a {
        width: 44px !important;
        height: 44px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 900px) {
    #map {
        height: calc(var(--vh, 1vh) * 100) !important;
    }
}

/* Zoom control end */